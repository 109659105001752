html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

blockquote,
body,
div,
figure,
footer,
form,
header,
html,
iframe,
label,
legend,
li,
nav,
object,
ol,
p,
section,
table,
ul {
  margin: 0;
  padding: 0;
  font-family: LabGrotesque, sans-serif;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IvarDisplay", Times, serif;
  font-weight: 700;
}

h1 {
  font-size: var(--chakra-fontSizes-4xl) !important;
}

h2 {
  font-size: var(--chakra-fontSizes-3xl) !important;
}

h3 {
  font-size: var(--chakra-fontSizes-2xl) !important;
}

h4 {
  font-size: var(--chakra-fontSizes-xl) !important;
}

@media screen and (max-width: 48em) {
  h1 {
    font-size: var(--chakra-fontSizes-3xl) !important;
  }

  h2 {
    font-size: var(--chakra-fontSizes-2xl) !important;
  }

  h3 {
    font-size: var(--chakra-fontSizes-xl) !important;
  }

  h4 {
    font-size: var(--chakra-fontSizes-lg) !important;
  }
}

@font-face {
  font-family: LabGrotesque;
  font-weight: 200;
  font-style: normal;
  src: url(./fonts/LabGrotesque-Thin.woff2) format("woff2");
}

@font-face {
  font-family: LabGrotesque;
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/LabGrotesque-Regular.woff2) format("woff2");
}

@font-face {
  font-family: LabGrotesque;
  font-weight: 300;
  font-style: normal;
  src: url(./fonts/LabGrotesque-Light.woff2) format("woff2");
}

@font-face {
  font-family: LabGrotesque;
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/LabGrotesque-Medium.woff2) format("woff2");
}

@font-face {
  font-family: IvarDisplay;
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/IvarDisplay-Regular.woff2) format("woff2");
}

@font-face {
  font-family: IvarText;
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/IvarText-Regular.woff2) format("woff2");
}

body {
  background: #f9f9f9;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  padding-top: 100px !important;
}

@media (max-width: 48em) {
  body {
    padding-top: 60px !important;
  }
}

.text-success {
  color: #43af43 !important;
}

p {
  line-height: 20px;
}

a,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: #388fc1;
}

a:hover {
  text-decoration: underline;
}

a.menu-item__link:hover {
  text-decoration: none;
}

a.link--unstyled,
a.link--unstyled:link,
a.link--unstyled:visited,
a.link--unstyled:active,
a.link--unstyled:hover {
  color: inherit;
  text-decoration: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

@media (max-width: 48em) {
  .visible-xs {
    display: block !important;
  }

  .row.visible-xs {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (min-width: 48em) and (max-width: 62em) {
  .visible-sm {
    display: block !important;
  }

  .row.visible-sm {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 62em) and (max-width: 75em) {
  .visible-md {
    display: block !important;
  }

  .row.visible-md {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 75em) {
  .visible-lg {
    display: block !important;
  }

  .row.visible-lg {
    display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (max-width: 48em) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 63em) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 64em) and (max-width: 76em) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 77em) {
  .hidden-lg {
    display: none !important;
  }
}

.leaflet-div-icon {
  background: none;
  border: none;
}

strong {
  font-weight: 500 !important;
 }

 .hover-underline-animation {
   display: inline-block;
   position: relative;
   color: #a7776c !important;
   text-decoration: none !important;
 }

 .hover-underline-animation::after {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 1px;
   bottom: 0;
   left: 0;
   background-color: #a7776c;
   transform-origin: bottom right;
   transition: transform 0.25s ease-out;
 }

 .hover-underline-animation:hover::after {
   transform: scaleX(1);
   transform-origin: bottom left;
 }
